html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  /* background-color: #101010; */
  background-color: white;
  /* overflow: hidden; */
}

#container {
  width: 100%;
  height: 100vh;
  scroll-behavior: smooth;
  overflow-y: scroll;
}

#aboutUs,
#services,
#contactUs {
  padding-top: 55px;
  background-color: #f8f8f8;
}

.nav-item {
  color: black;
  font-size: 0.75rem;
  font-weight: 500;
  cursor: pointer;
}

nav .navbar-nav li a {
  color: #fdfffc;
}

nav .navbar-nav li a :hover {
  color: #ffffff;
}

.navbar-light {
  color: #ffffff;
}

.navbar-toggler {
  color: #ffffff;
}

#home {
  background: url(/img/mystars.svg) no-repeat center center fixed;
  background-color: #011627;
  display: table;
  height: 100%;
  position: relative;
  width: 100%;
  background-size: cover;
  text-align: center;
}

#aboutUs,
#services,
#contactUs {
  background: url(/img/mystarsDark.svg) no-repeat center center fixed;
  background-size: cover;
  background-color: #fdfffc;
  border: 0px solid red;
  padding-top: 0;
}

.tr {
  display: table-row;
  border: 0px solid red;
}

.td {
  display: table-cell;
  border: 0px solid red;
}

#home .tr .td img {
  border: 0px solid blue;
  max-width: 80%;
  margin-bottom: 0;
}

#home .tr .td {
  vertical-align: bottom;
  padding-bottom: 90px;
}

.landing-text {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.landing-text img {
  max-width: 80%;
}

.section-heading {
  font-size: 300%;
  font-weight: 300;
  color: #73683b;
}

.padding {
  padding: 5px 0;
  padding-top: 20px;
  color: #73683b;
}

.padding img {
  width: 100%;
}

.fixed {
  background: url(/img/mic2blur2.jpg) no-repeat center center fixed;
  display: table;
  height: 60%;
  position: relative;
  width: 100%;
  background-size: cover;
}

.fixed2,
.fixed3 {
  background: url(/img/stars.svg) no-repeat center center fixed;
  background-color: #011627;
  display: table;
  height: 60%;
  position: relative;
  width: 100%;
  background-size: cover;
}

#testimonial h1 {
  font-size: 2.5rem;
  color: #fdfffc;
  font-weight: 200;
}

.carousel-item {
  /* top: 7%; */
  color: #fdfffc;
  border: 0px solid red;
  /* text-align: center; */
  font-weight: 100;
  /* vertical-align: middle; */
  /* margin: auto; */
}

.carousel-item div {
  border: 0px solid red;
  width: 75%;
  margin: auto;
}

.carousel-item h2 {
  font-weight: 600;
  font-size: 1.5rem;
}

.carousel-item p {
  font-style: italic;
  font-size: 1.2rem;
}

.carousel-item span {
  top: -100;
  font-size: 1.5rem;
  font-weight: 300;
}
.carousel-item span:nth-of-type(2) {
  font-size: 1rem;
  font-weight: 100;
}

.carousel-item hr {
  width: 150px;
  border: 1px solid #f71735;
}

.card {
  box-shadow: 0px 0px 4px #c0c0c0;
}

.footer {
  width: 100%;
  background: url(/img/stars.svg) no-repeat center center fixed;
  background-color: #011627;
  padding: 5% 5% 2% 5%;
  color: #73683b;
}

.copyright {
  font-size: x-small;
}

.fa-contact {
  color: #f71735;
}

.fa-connect {
  padding: 15px;
  color: white;
}

.fa-connect:hover {
  color: red;
  text-decoration: none;
}

.icon {
  max-width: 200px;
}

.about-fa {
  font-size: 5rem;
  color: #f71735;
}

h5.about-fa-txt {
  color: #f71735;
}
.about-fa-txt {
  font-weight: 100;
  color: #73683b;
}
.about-fa-border {
  border-top: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  padding: 30px;
}

.about-txt-border {
  border-top: 1px solid #cccccc;
}

.card-title {
  font-weight: 300;
  color: #73683b;
}

.card-text {
  font-weight: 100;
  color: #73683b;
}

div.alert {
  font-size: 0.75rem;
  padding: 7px;
}

/* Medium devices (tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .landing-text img {
    color: white;
    max-width: 60vw;
  }
  .padding {
    padding: 5px 0;
    padding-top: 20px;
  }
  .section-heading {
    font-size: 500%;
  }

  #testimonial h1 {
    font-size: 5rem;
  }

  .carousel-item {
    top: 30%;
  }
}

/* Large devices (desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #home .tr .td img {
    border: 0px solid blue;
    max-width: 60%;
  }

  .landing-text img {
    color: white;
    max-width: 50vw;
  }
  .padding {
    padding: 5px 0;
    padding-top: 20px;
  }
  .section-heading {
    font-size: 500%;
  }

  .carousel-item {
    top: 20%;
  }
}
